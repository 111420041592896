<template lang="pug">
    .mb-3.card-settings(v-if='item')
        div
            .mb-3.card-basic.card-settings__wrap(v-if='(item && item.isActive() && item.canSeeCover() && item.somePlansHasResourceActive() && !item.isNeuro()) || item.isNeuro() && item.isActive()')
                p.b1.card-settings__title(v-if="!item.isNeuro()") {{ $t('h1.paymentPageAddress') }}
                p.b1.card-settings__title(v-if="item.isNeuro()") {{ $t('h1.botPageAddress') }}
                .d-flex.justify-content-between.card-settings__card-link
                    .d-flex.flex-column
                        p.b2.text-grey.card-settings__subtitle {{ $t('project.paymentLinkDefaultConstant') }}
                        a.b1.text-break.text-primary(:href='paymentLinkDefault' target='_blank') {{ paymentLinkDefault }}
                    i.text-primary.fontello.fontello-icon-copy.cursor-pointer.btn-icon.card-settings__btn-icon(v-if="!isMobile" @click="copy(paymentLinkDefault)")
                    i.text-primary.fontello.fontello-icon-dots.mb-auto(v-if="isMobile" @click="onOpenPopover('popoverConstant')" id="link-constant")
                    b-popover(v-if="isMobile" ref="popoverConstant" target="link-constant" :custom-class="'popover-settings'")
                        i.fontello-btn.fontello-btn-primary.fontello-icon-copy(@click="copy(paymentLinkDefault), onClosePopover")

                .d-flex.justify-content-between.card-settings__card-link.mb-0(v-if='item && item.hashIdCustom != item.hashId')
                    .d-flex.flex-column
                        p.b2.text-grey.card-settings__subtitle {{ $t('project.paymentLinkDefaultAdditional') }}
                        a.b1.text-break.text-primary(:href='paymentLink' target='_blank') {{ paymentLink }}
                    i.text-primary.fontello.fontello-icon-edit.cursor-pointer.ml-auto.mr-2.btn-icon.card-settings__btn-icon(v-if="!isMobile" @click="$bvModal.show('edit-project-link'), editLink()")
                    i.text-primary.fontello.fontello-icon-copy.cursor-pointer.btn-icon.card-settings__btn-icon(v-if="!isMobile" @click="copy(paymentLink)")
                    i.text-primary.fontello.fontello-icon-dots.mb-auto(v-if="isMobile" @click="onOpenPopover('popoverAdditional')" id="link-additionals")
                    b-popover(v-if="isMobile" ref="popoverAdditional" target="link-additionals" :custom-class="'popover-link-paywall'")
                        i.mb-2.fontello-btn.fontello-btn-primary.fontello-icon-copy(@click="copy(paymentLink), onClosePopover")
                        i.fontello-btn.fontello-btn-primary.fontello-icon-edit(@click="$bvModal.show('edit-project-link'), editLink()")

                .d-flex.justify-content-between.align-items-center.mb-0.card-settings__card-link.is-additionals(v-else)
                    p.b2.text-grey.mb-0 {{ $t('project.addressPaymentPageChoose') }}
                    span.btn.btn-primary.card-settings__plus-icon(@click="$bvModal.show('edit-project-link'), editLink()")
                        span.m-0.plus-primary

            .mb-3.card-basic(v-if="!item.isNeuro()")
                .d-flex.justify-content-between.align-items-center
                    .d-flex.flex-column.mr-3
                        p.b1.mb-2 {{ $t('h1.safety') }}
                        span.b3.text-grey {{ PSafety ? $t('forms.safety') : $t('forms.unsafety') }}
                    switches(v-model="PSafety" :disabled='isPending')

            .mb-3.card-basic(v-if="!item.isNeuro()")
                .d-flex.justify-content-between.align-items-center
                    .d-flex.flex-column.mr-3
                        p.b1.mb-2 {{ $t('h1.softWork') }}
                        span.b3.text-grey {{ PSmooth ? $t('forms.softWork') : $t('forms.unSoftWork') }}
                    switches(v-model="PSmooth" :disabled='isPending')
                a.b3.text-primary(href="https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/tarify#myagkaya-rabota-s-probnymi-podpiskami" target='_blank') {{ $t('landing.cookieDesc') }}

            .mb-3.card-basic(v-if="!item.isNeuro()")
                .d-flex.justify-content-between.align-items-center
                    .d-flex.flex-column.mr-3
                        p.b1.mb-2 {{ $t('h1.smartSubscriptionRecovery') }}
                        span.b3.text-grey {{ PSmart ? $t('forms.smartSubscriptionRecovery') : $t('forms.smartSubscriptionRecoveryOff') }}
                    switches(v-model="PSmart" :disabled='isPending')
                a.b3.text-primary(href="https://docs.paywall.pw/rus/obshee/vosstanovlenie-podpisok" target='_blank') {{ $t('landing.cookieDesc') }}

            .mb-3.card-basic(v-if="!item.isNeuro()")
                .d-flex.justify-content-between.align-items-center
                    .d-flex.flex-column.mr-3
                        p.b1.mb-2 {{ $t('project.settingsDonation') }}
                        span.b3.text-grey {{ $t('project.settingsDonationOn') }}
                    switches(v-model="PDonation" :disabled="isPDonationPending || isPending")
                a.b3.text-primary(href="https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/tarify#platyat-bolshe" target='_blank') {{ $t('landing.cookieDesc') }}

            .mb-3.card-basic(v-if="!item.isNeuro()")
                .d-flex.justify-content-between.align-items-center
                    .d-flex.flex-column.mr-3
                        p.b1.mb-2 {{ $t('project.settingsRestored') }}
                        span.b3.text-grey {{ $t('project.settingsRestoredOn') }}
                    switches(v-model="PRestored" :disabled="isPDonationPending || isPending")

        .btn.btn-border.btn-small.mt-4.card-settings__button(v-if='!item.isNeuro() && !item.isArchived() && !isCanBeDeleted && !item.isBlocked()' @click='openModalRemoveProject') {{ $t('forms.archive') }}
        .btn.btn-border.btn-small.mt-4.card-settings__button(v-if='item.isArchived()' @click='unarchive') {{ $t('forms.unarchive') }}
        .btn.btn-border.btn-small.mt-4.card-settings__button.is-remove(v-if='!item.isNeuro() && isCanBeDeleted' @click='openModalRemoveProject') {{ $t('project.removeProject') }}
        .btn.btn-border.btn-small.mt-4.card-settings__button.is-remove(v-if='item.isNeuro() && isCanBeDeleted' @click='$bvModal.show("delete-project-neurobot")') {{ $t('project.removeProject') }}

        modal-remove-project(:item='itemRemoveProject' @success='itemRemoveProject = null')

        modal-general(v-if="!isMobile" :id="'edit-project-link'" :title="$t('project.addressPaymentPageAddon')")
            edit-project-link(:item='itemEditLinkProject' @close="$bvModal.hide('edit-project-link')")

        b-modal(v-if="isMobile" :id="'edit-project-link'" content-class="card-settings__modal" header-class="p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
            template(#modal-header="{ close }")
                h3.h3 {{ $t('project.addressPaymentPageAddon') }}
                icon-close(@click="close()")
            edit-project-link(:item='itemEditLinkProject' @close="$bvModal.hide('edit-project-link')")

        modal-general(v-if="!isMobile" :id="'delete-project-neurobot'" :title="$t('project.removeProject')")
            delete-project-neurobot

        b-modal(v-if="isMobile" :id="'delete-project-neurobot'" content-class="bot-setup__modal"  header-class="p-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
            template(#modal-header="{ close }")
                h3.h3 {{ $t('project.removeProject') }}
                icon-close(@click="close()")
            delete-project-neurobot
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Switches from 'vue-switches';
import ModalRemoveProject from '@/components/Modals/RemoveProject';
import EditProjectLink from '@/components/Common/EditProjectLink';
import { SMART_SUB_RECOVERY } from '@/models/project';
import 'vue-select/dist/vue-select.css';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import IconClose from '@/components/Common/IconClose';
import DeleteProjectNeurobot from '@/components/Common/DeleteProjectNeurobot';

export default {
    name: 'Settings',
    components: {
        Switches,
        ModalRemoveProject,
        EditProjectLink,
        ModalGeneral,
        IconClose,
        DeleteProjectNeurobot,
    },
    data: () => ({
        overlay: false,
        PDonation: false,
        PSafety: false,
        SWork: false,
        PSmart: false,
        PSmooth: false,
        PRestored: false,
        isPSmartPending: false,
        isPDonationPending: false,
        itemRemoveProject: null,
        itemEditLinkProject: null,
        canBeDeleted: false,
        pageColor: false,
    }),
    computed: {
        ...mapGetters({
            item: 'project/opened',
            isPending: 'project/isPending',
            isCanBeDeleted: 'project/canBeDeleted',
        }),
        paymentLink() {
            return this.item ? `${this.paymentLinkDomain}${this.item.hashIdCustom}` : '';
        },
        paymentLinkDefault() {
            return this.item ? `${this.paymentLinkDomain}${this.item.hashId}` : '';
        },
        paymentLinkDomain() {
            return `${window.location.protocol}//${window.location.host.replace('merchant.', '')}/`;
        },
        colors() {
            return [
                { id: false, title: this.$t('project.light') },
                { id: true, title: this.$t('project.dark') },
            ];
        },
    },
    mounted() {
        window.addEventListener('click', e => {
            if (this.overlay === true) {
                const target = e.target;

                if (!target.closest('.b-popover') && !target.closest('.fontello-icon-dots')) {
                    this.onClosePopover();
                }
            }
        });
    },
    methods: {
        ...mapActions({
            edit: 'project/edit',
            activate: 'project/activate',
        }),
        goToProjects() {
            this.$router.replace({ name: 'projects' });
        },
        openModalRemoveProject() {
            this.itemRemoveProject = null;

            this.$nextTick(() => {
                this.itemRemoveProject = this.item;
            });
        },
        unarchive() {
            this.activate({ id: this.item.id }).then(i => {
                this.$store.commit('project/activate', this.item.id);
                this.$notify('success', this.$t('success.projectActivated'));
            });
        },
        editLink() {
            this.onClosePopover();
            this.itemEditLinkProject = null;

            this.$nextTick(() => {
                this.itemEditLinkProject = this.item;
            });
        },
        async copy(text) {
            try {
                await this.$copyText(text);
                this.$notify('success', `😊 &nbsp;&nbsp;&nbsp;${this.$t('success.copyLink')}`);
            } catch (e) {
                console.log(e);
            }
        },
        onOpenPopover(refs) {
            if (refs == 'popoverConstant') {
                this.$refs.popoverConstant.$emit('open');
            } else if (refs == 'popoverAdditional') {
                this.$refs.popoverAdditional.$emit('open');
            }
            document.body.classList.add('overlay');
            this.overlay = true;
        },
        onClosePopover() {
            if (this.$refs.popoverConstant) {
                this.$refs.popoverConstant.$emit('close');
            }

            if (this.$refs.popoverAdditional) {
                this.$refs.popoverAdditional.$emit('close');
            }
            document.body.classList.remove('overlay');
            this.overlay = false;
        },
    },
    watch: {
        PSafety(nv) {
            if (this.item && nv != this.item.isModerateChats) {
                let canBeDeleted = this.isCanBeDeleted;
                this.edit({ id: this.item.id, isModerateChats: nv }).then(v => (this.item.canBeDeleted = canBeDeleted));
            }
        },

        PSmooth(nv) {
            if (this.item && nv != this.item.isKickTrialsEarly) {
                let canBeDeleted = this.isCanBeDeleted;
                this.edit({ id: this.item.id, isKickTrialsEarly: !nv }).then(
                    v => (this.item.canBeDeleted = canBeDeleted)
                );
            }
        },

        PSmart(nv) {
            if (this.item && nv != this.item.errorStrategy) {
                let canBeDeleted = this.isCanBeDeleted;
                this.isPSmartPending = true;
                this.edit({
                    id: this.item.id,
                    errorStrategy: { value: nv ? SMART_SUB_RECOVERY[0] : SMART_SUB_RECOVERY[1] },
                })
                    .then(v => {
                        this.item.canBeDeleted = canBeDeleted;
                        this.isPSmartPending = false;
                    })
                    .catch(v => (this.isPSmartPending = false));
            }
        },

        PRestored(nv) {
            if (this.item && nv != this.item.isAllowRestoredSubscriptions) {
                let canBeDeleted = this.isCanBeDeleted;
                this.isAllowRestoredSubscriptions = true;
                this.edit({
                    id: this.item.id,
                    isAllowRestoredSubscriptions: nv,
                })
                    .then(v => {
                        this.item.canBeDeleted = canBeDeleted;
                        this.isAllowRestoredSubscriptions = true;
                    })
                    .catch(v => (this.isAllowRestoredSubscriptions = false));
            }
        },

        PDonation(nv) {
            if (this.item && nv != this.item.isAllowedExtraPrice) {
                let canBeDeleted = this.isCanBeDeleted;
                this.isPDonationPending = true;
                this.edit({ id: this.item.id, isAllowedExtraPrice: nv })
                    .then(v => {
                        this.item.canBeDeleted = canBeDeleted;
                        this.isPDonationPending = false;
                    })
                    .catch(v => (this.isPDonationPending = false));
            }
        },

        pageColor(nv) {
            if (this.item && nv != this.item.isPaymentPageDark) {
                let canBeDeleted = this.isCanBeDeleted;

                this.edit({ id: this.item.id, isPaymentPageDark: nv }).then(v => {
                    this.item.isPaymentPageDark = v.isPaymentPageDark;
                    this.item.canBeDeleted = canBeDeleted;
                });
            }
        },

        item: function (nv, ov) {
            this.PDonation = this.item ? this.item.isAllowedExtraPrice : null;
            this.PSafety = this.item ? this.item.isModerateChats : null;
            this.PSmooth = this.item ? this.item.isKickTrialsEarly : null;
            this.PRestored = this.item ? this.item.isAllowRestoredSubscriptions : null;
            this.PSmart =
                this.item && this.item.errorStrategy && this.item.errorStrategy == SMART_SUB_RECOVERY[0] ? true : false;
            this.pageColor = this.item ? this.item.isPaymentPageDark : null;

            if (ov && ov.id) {
                this.item.canBeDeleted = this.isCanBeDeleted;
            }
        },
    },
    created() {
        this.PDonation = this.item ? this.item.isAllowedExtraPrice : false;
        this.PSafety = this.item ? this.item.isModerateChats : false;
        this.PSmooth = this.item ? this.item.isKickTrialsEarly : false;
        this.PRestored = this.item ? this.item.isAllowRestoredSubscriptions : null;
        this.PSmart =
            this.item && this.item.errorStrategy && this.item.errorStrategy == SMART_SUB_RECOVERY[0] ? true : false;
        this.pageColor = this.item && this.item.isPaymentPageDark ? this.item.isPaymentPageDark : this.colors[0].id;
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.card-settings {
    max-width: 935px;

    &__wrap {
        padding-top: 18px;

        @include respond-below(sm) {
            margin-top: -1px;
            padding-top: 14px;
        }
    }

    &__title {
        margin-bottom: 10px !important;

        @include respond-below(sm) {
            margin-bottom: 15px !important;
        }
    }

    &__card-link {
        padding: 21px 20px 24px;
        border-radius: var(--border-radius-rounded);
        background-color: var(--bg-back);
        margin-bottom: 9px;

        @include respond-below(sm) {
            padding: 16px 13px 15px;
            margin-bottom: 15px;
        }

        &.is-additionals {
            padding: 16px 20px 21px;

            @include respond-below(sm) {
                padding: 15px 13px;
            }
        }

        .fontello-icon-dots {
            margin-top: -2px;
        }
    }

    &__subtitle {
        margin-bottom: 5px;

        @include respond-below(sm) {
            margin-bottom: 4px;
        }
    }

    &__modal {
        padding: 16px 20px 28px;
    }

    &__button {
        max-width: 320px;
        height: 50px !important;

        @include respond-below(sm) {
            max-width: 100%;
        }

        &.is-remove {
            border: 1px solid var(--brand-error);
            background-color: var(--foreground-color);
            color: var(--brand-error) !important;

            &:hover {
                color: var(--foreground-color) !important;
                background-color: var(--error-color-hover);
                border-color: var(--error-color);
                box-shadow: none;
            }
        }
    }

    &__btn-icon {
        padding: 10.5px 10px !important;
        width: 41px !important;
        max-width: 41px !important;
        min-width: 41px !important;
        height: 40px !important;
        margin-top: 17px;
        margin-bottom: -10px;
    }

    &__plus-icon {
        width: 32px;
        max-width: 32px;
        min-width: 32px;
        height: 32px;
        max-height: 32px;
        min-width: 32px;
        padding: 7px;
        box-shadow: none !important;
        margin-top: 5px;
        margin-bottom: -1px;
        margin-right: 4.5px;
        border-radius: 6px !important;

        @include respond-below(sm) {
            margin: 0;
        }

        &:hover,
        &:active {
            background-color: var(--brand-primary) !important;
            box-shadow: none !important;
        }
    }
}
</style>
